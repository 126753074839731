import { FlexAlignStart, FlexCenter, FlexColumn, } from '../shared/Flex';
import { Text } from '../ui-elements/Main/Text';
import styled from 'styled-components';

export const MenuNotif = ({
  icon,
  title,
  subtitle,
}) => {
  return (
    <Container>
      <Icon>
        {icon}
      </Icon>
      <FlexColumn>
        <Text fontSize="14px">
          {title}
        </Text>
        <Text fontSize="12px">
          {subtitle}
        </Text>
      </FlexColumn>
    </Container>
  )
}

const Container = styled(FlexAlignStart)`
  cursor: pointer;
  justify-content: flex-start;
  padding: 15px 25px 15px 15px;
  border-bottom: 1px solid rgba(224, 198, 183, 0.5);
`

const Icon = styled(FlexCenter)`
  padding: 7.5px;
  border-radius: 50px;
  background: rgba(251, 248, 243, 1);
  margin-right: 10px;
  min-width: 31px;
  min-height: 31px;
`