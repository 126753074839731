import { useState, useRef } from 'react';
import { MenuItem } from '../header/MenuItem';
import { MenuContainer } from '../header/MenuContainer';
import { FlexAlignCenter, FlexColumn } from '../shared/Flex';
import styled from 'styled-components';
import { useClickOutside } from '@/helpers/app/hooks';
import { Text } from '../ui-elements/Main/Text';
import { ToolTip } from './ToolTip';
import { useHover } from '@/helpers/app/hooks';
import { useViewport } from '@/lib/layout/dimensions';

export const MenuDropdown = ({ trigger, menuHeader = null, menuItems, tooltip = null }) => {
  const ref = useRef();
  const hoverRef = useRef();
  const isMobile = useViewport((width) => width < 600, 500);
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);

  useClickOutside(ref, setShow);
  useHover(hoverRef, setHover);

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <FlexAlignCenter
        ref={hoverRef}
        onClick={() => setShow(!show)}
        style={{ cursor: 'pointer' }}
      >
        {trigger}
      </FlexAlignCenter>
      {!isMobile && tooltip && hover && (
        <ToolTip>{tooltip}</ToolTip>
      )}
      {show && (
        <MenuContainer>
          {menuHeader}
          <MenuItems>
            {menuItems.map(({ textColor, icon, title, callback }) => (
              <MenuItem
                icon={icon}
                textColor={textColor}
                title={title}
                callback={callback}
              />
            ))}
          </MenuItems>
        </MenuContainer>
      )}
    </div>
  )
}

const Tooltip = styled(Text)`
  background: black;
  padding: 12px 20px;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  min-width: fit-content;
  right: 50%;
`
const MenuItems = styled(FlexColumn)`
  padding: 10px;
`;