export const dateStringToUnix = (str: string) => {
  const date = new Date(str);
  return date.getTime() / 1000;
};

export const msToSecs = (ms: number) => ms / 1000;
export const secsToMs = (secs: number) => secs * 1000;

export const destructureDate = (date: Date) => {
  const year = date.getFullYear();
  const ten = 10;
  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();
  let hour: number | string = date.getHours();
  let min: number | string = date.getMinutes();

  month = month < ten ? `0${month}` : month;
  day = day < ten ? `0${day}` : day;
  hour = hour < ten ? `0${hour}` : hour;
  min = min < ten ? `0${min}` : min;

  return { year, month, day, hour, min };
};

export const formatDateForDateTimeInput = (date: ReturnType<typeof destructureDate>) => {
  const { year, month, day, hour, min } = date;
  return `${year}-${month}-${day}T${hour}:${min}`;
};

export const dateToUnix = (date: Date) => {
  return date.getTime() / 1000;
};

const oneDay = 24 * 60 * 60 * 1000;

export type Interval = 'today' | '24-hours' | '7-days' | '30-days' | 'this-billing-period' | 'all-time';

export const getFromAndToUnix = (
  interval: Interval,
  billingPeriodStart?: string,
  accountCreated?: string
) => {
  let from = new Date();
  let to = new Date();

  // default "to" to EOD
  to.setHours(23, 59, 59, 999);

  switch (interval) {
    case 'today':
      from.setHours(0, 0, 0, 0);
      break;
    case '24-hours':
      from = new Date(from.getTime() - oneDay);
      to = new Date();
      break;
    case '7-days':
      from = new Date(from.getTime() - (7 * oneDay));
      break;
    case '30-days':
      from = new Date(from.getTime() - (30 * oneDay));
      break;
    case 'this-billing-period':
      from = new Date(billingPeriodStart);
      break;
    case 'all-time':
      from = new Date(accountCreated);
      break;
    default: // 30 days
      from = new Date(from.getTime() - (30 * oneDay));
      break;
  }

  return [dateToUnix(from), dateToUnix(to)];
};

export const formatSeconds = (seconds) => {
  const week = 604800;
  const day = 86400;
  const hour = 3600;
  const minute = 60;
  switch (true) {
    case seconds >= week:
      return `${Math.floor(seconds / week)}w${Math.floor((seconds % week) / day)}d`;
    case seconds >= day:
      return `${Math.floor(seconds / day)}d${Math.floor((seconds % day) / hour)}h`;
    case seconds >= hour:
      return `${Math.floor(seconds / hour)}h${Math.floor((seconds % hour) / minute)}m`;
    case seconds >= minute:
      return `${Math.floor(seconds / minute)}h${Math.floor((seconds % minute))}m`;
    default:
      return `${Math.floor(seconds / minute)}s`;
  }
};

// ex. Dec 11, 2024, 10:56 AM
export const shortDateTimeFormat = {
  day: 'numeric' as const,
  month: 'short' as const,
  year: 'numeric' as const,
  hour12: true,
  hour: 'numeric' as const,
  minute: 'numeric' as const,
};

export const dateFormat = {
  day: 'numeric' as const,
  month: 'long' as const,
  hour12: true,
  hour: 'numeric' as const,
  minute: 'numeric' as const,
};

export const dateMidFormat = {
  day: 'numeric' as const,
  month: 'short' as const,
  hour12: true,
  year: 'numeric' as const,
};

export const shortDateFormat = {
  day: 'numeric' as const,
  month: 'numeric' as const,
  year: 'numeric' as const,
};

export const timeFormat = {
  hour12: true,
  hour: 'numeric' as const,
  minute: 'numeric' as const,
};

type Milliseconds = number;

export const getStartOfDay = (date: Date = new Date()): Milliseconds => {
  date.setHours(0, 0, 0, 0);
  return date.getTime();
}

export const getEndOfDay = (date: Date = new Date()): Milliseconds => {
  date.setHours(23, 59, 59, 999);
  return date.getTime();
}

export const getOneWeekAgoBeginningOfDay = (): Milliseconds => {
  return getStartOfDay() - (7 * oneDay);
}

export const formatDateForDateInput = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const dd = day < 10 ? `0${day}` : day;
  const mm = month < 10 ? `0${month}` : month;
  return `${year}-${mm}-${dd}`;
};
