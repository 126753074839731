import { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../ui-elements/Main/MainTheme";
import { Volume2, VolumeX } from "react-feather";
import { Text } from "../ui-elements/Main/Text";
import { useHover } from "@/helpers/app/hooks";
import { UserInfoContext } from "../user/user-info";
import { toggleAudio } from "@/redux/app/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { userAudioSelector } from "@/redux/app/selectors/user";
import { useViewport } from '@/lib/layout/dimensions';

export const ToggleSound = () => {
  const dispatch = useDispatch();
  const { userId } = useContext(UserInfoContext);
  const audio = useSelector(userAudioSelector);
  const [hover, setHover] = useState(false);
  const isMobile = useViewport((width) => width < 600, 500);
  const ref = useRef();

  useHover(ref, setHover);

  return (
    <>
      {/*
        to do:
          browser notification logic within Header.tsx
          currently leveraging JS including via _browser_notifications.html.erb

        1. port classes into react
        2. contain set-up logic within header
        3. use audioSelector as toggle source of truth
        4. add last notified state into a react context
      */}
      <Wrap
        id="audio-toggler"
        ref={ref}
        className={audio ? 'react on' : 'react'}
        onClick={() => dispatch(toggleAudio({ userId }))}
      >
        {audio ? <Volume2 />: <VolumeX />}
        {!isMobile && hover && (
          <Tooltip
            display='flex'
            fontSize="14px"
            color={colors.white}
          >
            {audio ? 'Mute Alerts' : 'Unmute Alerts'}
          </Tooltip>
        )}
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  background: ${colors.sand};
  padding: 5px;
  height: 36px;
  width: 36px;
  border: 1px solid rgba(224, 198, 183, 0.2);
  border-radius: 50%;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const Tooltip = styled(Text)`
  background: black;
  padding: 12px 20px;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  min-width: fit-content;
  right: 50%;
`