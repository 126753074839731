import { useContext } from 'react';
import { Home, CreditCard } from 'react-feather'
import { MenuDropdown } from './MenuDropdown';
import { BalanceMenuHeader } from './BalanceMenuHeader';
import { BalanceTrigger } from './BalanceTrigger';
import { AccountInfoContext } from '../account/account-info';
import { accountBillingPath } from '@/helpers/app/routes';
import { useNavigationActions } from '@/lib/navigation';

export const BalanceDropDown = () => {
  const { subdomain } = useContext(AccountInfoContext);
  const { navigate } = useNavigationActions();
  const size = 18;
  const balanceItems = [
    { title: 'Add more balance', icon: <Home size={size}/>, callback: () => navigate({ path: accountBillingPath(subdomain), params: {} })},
    { title: 'View Billing & Subscription', icon: <CreditCard size={size}/>, callback: () => navigate({ path: accountBillingPath(subdomain), params: {} }) },
  ]

  return (
    <MenuDropdown
      tooltip="Account Balance"
      menuItems={balanceItems}
      menuHeader={<BalanceMenuHeader />}
      trigger={<BalanceTrigger />}
    />
  )
}