import { useContext } from "react";
import styled from "styled-components";
import { Span } from "../ui-elements/Main/Span";
import { Text } from "../ui-elements/Main/Text";
import { colors } from "../ui-elements/Main/MainTheme";
import { FlexCenter, FlexColumn, FlexJustifyBetween } from "../shared/Flex";
import { useDispatch } from "react-redux";
import { markAsRead } from "@/redux/app/actions/notification";
import { UserInfoContext } from '../user/user-info';
import { evaluateVars } from "@/helpers/app/notification";
import { Notification as NotificationInterface } from '@/redux/app/reducers/notification';
import { formatDistance } from "date-fns";
import { secsToMs } from "@/helpers/app/time";

const { audienceLogGray, grayish, burntOrange } = colors;

export const Notification = ({ notification }: { notification: NotificationInterface }) => {
  const dispatch = useDispatch();
  const { userId } = useContext(UserInfoContext);
  const { id, created_at, read_at } = notification;
  const { header, message, path, navigationAction } = evaluateVars(notification);

  const navigateAndMark = (id) => {
    if (!notification.read_at) {
      dispatch(markAsRead({ userId, appNotificationId: id }));
    }
    dispatch(navigationAction({ path, params: {} }));
  }

  return (
    <ListItemContainer onClick={(e) => navigateAndMark(id)}>
      <ListItem>
        <FlexColumn>
          <Text
            fontWeight={800}
            fontSize="14px"
            margin="0 0 4px 0"
            lineHeight={1.2}
          >
            {header}
            <Span>&nbsp;{message}</Span>
          </Text>
          <Text
            fontSize="12px"
            color={grayish}
          >
            {formatDistance(secsToMs(created_at), new Date(), { addSuffix: true })}
          </Text>
        </FlexColumn>
        {!read_at && <Circle />}
      </ListItem>
    </ListItemContainer>
  )
}

const ListItemContainer = styled(FlexCenter)`
  border-bottom: 1px solid ${audienceLogGray};
  cursor: pointer;
  height: auto;
  width: 100%;
`;

const ListItem = styled(FlexJustifyBetween)`
  padding: 15px;
  border-radius: 10px;
  margin: 5px;
  width: 100%;

  &:hover {
    background: #FFF4E1;
  }
`;

const Circle = styled.div`
  margin-top: 4px;
  height: 10px;
  min-width: 10px;
  border-radius: 8px;
  position: relative;
  background: ${burntOrange};
`;