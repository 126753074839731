import type { ReduxState } from '@/redux/app/state';

export const orgLoadingStatusSelector = (state: ReduxState) =>
  state.organization.loadingStatus;

export const orgSetCheckoutStatusSelector = (state: ReduxState) =>
  state.organization.setCheckoutStatus;

export const orgBillingErrorSelector = (state: ReduxState) =>
  state.organization.billingError;

export const orgGetSeatSummaryStatusSelector = (state: ReduxState) =>
  state.organization.getSeatSummaryStatus;

export const orgGetUpgradeSummaryStatusSelector = (state: ReduxState) =>
  state.organization.getUpgradeSummaryStatus;

export const orgUsageLoadingStatusSelector = (state: ReduxState) =>
  state.organization.usageLoadingStatus;

export const orgStripeSessionSelector = (state: ReduxState) =>
  state.organization.stripeSessionId;

export const orgUpdateAutoFillStatusSelector = (state: ReduxState) =>
  state.organization.updateAutoFillStatus;

export const orgStripeCheckoutStatusSelector = (state: ReduxState) =>
  state.organization.stripeCheckoutStatus;

export const orgCancelSubscriptionSelector = (state: ReduxState) =>
  state.organization.cancelSubscription;

export const orgPendingCancellationSelector = (state: ReduxState) =>
  state.organization.pendingCancellation;

export const orgStripeCustomerPortalUrlSelector = (state: ReduxState) =>
  state.organization.stripeCustomerPortalSessionUrl;

export const orgIdSelector = (state: ReduxState) =>
  state.organization.id;

export const orgPlanSelector = (state: ReduxState) =>
  state.organization.plan;

export const orgPlanNameSelector = (state: ReduxState) =>
  state.organization.planName;

export const orgPayPeriodSelector = (state: ReduxState) =>
  state.organization.payPeriod;

export const orgNameSelector = (state: ReduxState) =>
  state.organization.name;

export const orgSeatsSelector = (state: ReduxState) =>
  state.organization.seats;

export const orgSeatsPendingSelector = (state: ReduxState) =>
  state.organization.seatsPending;

export const orgSeatsInUseSelector = (state: ReduxState) =>
  state.organization.seatsInUse;

export const orgSeatsAvailableSelector = (state: ReduxState) =>
  state.organization.seatsAvailable;

export const orgBalanceRemainingSelector = (state: ReduxState) =>
  state.organization.balanceRemaining;

export const orgIsLowBalanceNotificationSelector = (state: ReduxState) =>
  state.organization.isLowBalanceNotification;

export const orgAutoFillSelector = (state: ReduxState) =>
  state.organization.autoFill;

export const orgAutoFillBalanceSelector = (state: ReduxState) =>
  state.organization.autoFillBalance;

export const orgAutoFillThresholdSelector = (state: ReduxState) =>
  state.organization.autoFillThreshold;

export const orgPaymentMethodFailedSelector = (state: ReduxState) =>
  state.organization.paymentMethodFailed;

export const orgPhoneNumbersSelector = (state: ReduxState) =>
  state.organization.phoneNumbers;

export const orgSubscriptionEndSelector = (state: ReduxState) =>
  state.organization.subscriptionEnd;

export const orgHasStripeSubscriptionSelector = (state: ReduxState) =>
  state.organization.hasStripeSubscription;

export const orgIsCancellationPendingSelector = (state: ReduxState) =>
  state.organization.isCancellationPending;

export const orgIsChurnedSelector = (state: ReduxState) =>
  state.organization.isChurned;

export const orgCheckoutSelector = (state: ReduxState) =>
  state.organization.checkout;

export const orgCheckoutProcessingResponseMessage = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.processingResponseMessage;
};

export const orgCheckoutProcessingResponseStatus = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.processingResponseStatus;
};

export const orgCheckoutLineItemsSelector = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.lineItems;
};

export const orgCheckoutSeatQuantitySelector = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.seatQuantity;
};

export const orgCheckoutBalanceSelector = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.balance;
};

export const orgCheckoutProjectedUpcomingAmountDueSelector = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.projectedUpcomingAmountDue;
};

export const orgCheckoutTotalTodaySelector = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.totalToday;
};

export const orgCheckoutSeatPriceSelector = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.seatPrice;
};

export const orgCheckoutSeatProratedPriceSelector = (state: ReduxState) => {
  const checkoutSelector = orgCheckoutSelector(state);
  return checkoutSelector.seatProratedPrice;
};

export const orgBalanceOptionsSelector = (state: ReduxState) =>
  state.organization.balanceOptions;

export const orgThresholdOptionsSelector = (state: ReduxState) =>
  state.organization.thresholdOptions;

export const orgUsageSelector = (state: ReduxState) =>
  state.organization.usage;

export const orgIsStripeCustomerSelector = (state: ReduxState) =>
  state.organization.isStripeCustomer;

export const orgLastBilledSelector = (state: ReduxState) =>
  state.organization.lastBilled;

export const orgAccountsSelector = (state: ReduxState) =>
  state.organization.accounts;

export const orgSubscriptionsSelector = (state: ReduxState) =>
  state.organization.inAppSubscriptions;

export const orgOneTimePurchasesSelector = (state: ReduxState) =>
  state.organization.oneTimePurchases;

export const orgIsCreditCardOnFileSelector = (state: ReduxState) =>
  state.organization.isCreditCardOnFile;

export const orgCancelStatusSelector = (state: ReduxState) =>
  state.organization.cancelStatus;

export const orgCancelMessageSelector = (state: ReduxState) =>
  state.organization.cancelMessage;

export const orgUncancelStatusSelector = (state: ReduxState) =>
  state.organization.uncancelStatus;

export const orgUncancelMessageSelector = (state: ReduxState) =>
  state.organization.uncancelMessage;
