import { useRef, useState, useContext } from "react";
import styled from "styled-components";
import { colors } from "../ui-elements/Main/MainTheme";
import { Bell } from "react-feather";
import { Text } from "../ui-elements/Main/Text";
import { FlexColumn, FlexJustifyBetween } from "../shared/Flex";
import { Span } from "../ui-elements/Main/Span";
import { useClickOutside, useHover } from "@/helpers/app/hooks";
import { ToolTip } from "./ToolTip";
import { useViewport } from '@/lib/layout/dimensions';
import { useFetchAppNotifications } from "@/hooks/data-fetching/useFetchNotifications";
import {
  notificationUnreadSelector,
  notificationUnreadLoadingSelector,
  notificationAnnouncementsUnreadSelector,
} from "@/redux/app/selectors/notification";
import { useDispatch, useSelector } from "react-redux";
import { UserInfoContext } from '../user/user-info';
import { ActionStatus } from "thunkless";
import { fetchUnreadCount, fetchProductReleaseAppNotifications } from "@/redux/app/actions/notification";
import { MarkAllAsRead } from "./MarkAllAsRead";
import { useInit } from "@/lib/react-hooks";
import { PaneHeaders } from "../billing/PaneHeaders";
import { Announcements } from "./Announcements";
import { Updates } from "./Updates";

const { audienceLogGray, white, sand, burntOrange, grayish } = colors;

export const ToggleNotifications = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [hover, setHover] = useState(false);
  const [tab, setTab] = useState<'updates'|'announcements'>('updates');
  const isMobile = useViewport((width) => width < 600, 500);
  const ref = useRef();
  const hoverRef = useRef();
  const { userId } = useContext(UserInfoContext);

  useClickOutside(ref, setToggle);
  useHover(hoverRef, setHover);
  useInit(() => {
    dispatch(fetchUnreadCount({ userId }));
    dispatch(fetchProductReleaseAppNotifications({ userId }))
  });

  const now = new Date().getTime().toString();
  useFetchAppNotifications(now);

  const unread = useSelector(notificationUnreadSelector);
  const announcementsUnread = useSelector(notificationAnnouncementsUnreadSelector);
  const unreadLoading = useSelector(notificationUnreadLoadingSelector)

  const UNSEEN_LIMIT = 9;
  const TEN_PLUS = '10+';

  const totalUnread = unread + announcementsUnread;
  const isTotalUnreadLimit = totalUnread > UNSEEN_LIMIT;
  const totalUnreadText = isTotalUnreadLimit ? TEN_PLUS : totalUnread;
  const isShowTotalUnreadCount = totalUnread > 0;
  const isUpdatesUnreadLimit = unread > UNSEEN_LIMIT;
  const updatesUnreadText = isUpdatesUnreadLimit ? TEN_PLUS : unread;
  const isShowUpdatesUnreadCount = unread > 0;

  const isAnnouncementsUnreadLimit = announcementsUnread > UNSEEN_LIMIT;
  const announcementsUnreadText = isAnnouncementsUnreadLimit ? TEN_PLUS : announcementsUnread;
  const isShowAnnouncementsUnreadCount = announcementsUnread > 0;

  return (
    <div ref={ref} style={{ position: isMobile ? 'static' : 'relative' }}>
      <Wrap
        ref={hoverRef}
        onClick={() => {
          setToggle(!toggle)
          setHover(false);
        }}
      >
        {unreadLoading === ActionStatus.SUCCESS && isShowTotalUnreadCount && (
          <Unseen
            $isUnreadLimit={isTotalUnreadLimit}
            fontSize="10px"
            fontWeight={800}
            color={white}
          >
            {totalUnreadText}
          </Unseen>
        )}
        <Bell style={{ cursor: 'pointer' }}/>
        {!isMobile && hover && !toggle && (
          <ToolTip>Notifications</ToolTip>
        )}
      </Wrap>
      {toggle && (
        <Container $isMobile={isMobile} onClick={(e) => e.stopPropagation()}>
          <HeaderContainer>
            <FlexJustifyBetween>
              <Text fontWeight={800} fontSize="14px">
                Notifications
                {isShowTotalUnreadCount && (
                  <Span fontWeight={800} color={burntOrange}>
                    &nbsp;({totalUnreadText})
                  </Span>
                )}
              </Text>
              <MarkAllAsRead />
            </FlexJustifyBetween>
            <PaneHeaders
              setPane={(key) => setTab(key)}
              pane={tab}
              buttonMargin="10px;"
              headerData={[
                { key: 'updates', name: isShowUpdatesUnreadCount ? `Updates (${updatesUnreadText})` : 'Updates' },
                { key: 'announcements', name: isShowAnnouncementsUnreadCount ? `Announcements (${announcementsUnreadText})` : 'Announcements' },
              ]}
            />
          </HeaderContainer>
          {tab === 'updates' && (
            <>
              <TextWrap>
                <Text fontSize="12px" padding="8px 20px" color={grayish} borderBottom={`1px solid ${audienceLogGray}`}>
                  Received in the last month.
                </Text>
              </TextWrap>
              <Updates />
            </>
          )}
          {tab === 'announcements' && <Announcements />}
        </Container>
      )}
    </div>
  )
}

const Unseen = styled(Text)<{
  isUnreadLimit: boolean;
}>`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -5px;
  right: -5px;
  background: ${burntOrange};
  color: white;
  border-radius: 8px;
  min-width: ${({ $isUnreadLimit }) => $isUnreadLimit ? '24px' : '16px'};
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
`;

const HeaderContainer = styled(FlexColumn)`
  padding: 20px 20px 0px 20px;
  border-bottom: 1px solid ${audienceLogGray};
  z-index: 1;
`;
const TextWrap = styled.div`
  z-index: 1;
`;

const Container = styled(FlexColumn)<{
  isMobile: boolean;
}>`
  position: absolute;
  background: white;
  right: 0;
  width: 100%;
  ${({ $isMobile }) => $isMobile
    ? ''
    : `
      min-width: 400px;
      right: 50%;
    `}
  border-radius: 16px;
  border: 1px solid ${audienceLogGray};
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  user-select: none;
`;

const Wrap = styled.div`
  position: relative;
  margin-right: 10px;
  background: ${sand};
  padding: 5px;
  height: 36px;
  width: 36px;
  border: 1px solid rgba(224, 198, 183, 0.2);
  border-radius: 50%;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
