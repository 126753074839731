import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../ui-elements/Main/Text';
import { FlexAlignCenter, FlexColumnAlignCenter } from '../shared/Flex';
import { colors } from '../ui-elements/Main/MainTheme';
import { ArrowUpCircle } from 'react-feather';
import styled from 'styled-components';
import { OrgInfoContext } from '../organization/org-info';
import { balanceToUsd } from '@/helpers/app/balance';
import { orgBalanceRemainingSelector } from '@/redux/app/selectors/organization';

export const BalanceTrigger = () => {
  const { autoFill } = useContext(OrgInfoContext);
  const balanceRemaining = useSelector(orgBalanceRemainingSelector);

  const size = 18;
  return (
    <Balance>
      <Text fontSize="11px">
        Balance
      </Text>
      <FlexAlignCenter>
        <Text fontSize="14px" fontWeight={800} color={colors.balanceGreen}>
          {balanceToUsd(balanceRemaining)}
        </Text>
        {autoFill && <Arrow size={size} />}
      </FlexAlignCenter>
    </Balance>
  )
}

const Balance = styled(FlexColumnAlignCenter)`
  cursor: pointer;
  margin-right: 10px;
  padding: 11px 30px;
  background: ${colors.sand};
`;

const Arrow = styled(ArrowUpCircle)`
  margin-left: 5px;
  rotate: 45deg;
`;
