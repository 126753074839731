import { useContext } from 'react';
import { FlexCenter } from "../shared/Flex"
import { UserInfoContext } from '../user/user-info';
import { colors } from '../ui-elements/Main/MainTheme';

export const Avatar = () => {
  const {
    avatar = 'https://pbs.twimg.com/profile_images/1237550450/mstom_400x400.jpg',
    initials
  } = useContext(UserInfoContext);
  const size = "36px";
  return (
    avatar ? (
      <img
        src={avatar}
        style={{ height: size,
          width: size,
          borderRadius: '50%',
          marginRight: '10px'
        }}
      />
    ) : (
      <FlexCenter
        style={{ fontSize: '14px',
          color: 'white',
          background: colors.seaGreen,
          height: size,
          width: size,
          borderRadius: '50%',
          marginRight: '10px'
        }}
      >
        {initials}
      </FlexCenter>
    )
  )
}