import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FlexColumn, FlexColumnAlignCenter } from '../shared/Flex';
import { Text } from '../ui-elements/Main/Text';
import { colors } from '../ui-elements/Main/MainTheme';
import { ArrowUpCircle } from 'react-feather';
import { MenuNotif } from './MenuNotif';
import { OrgInfoContext } from '../organization/org-info';
import { balanceToUsd } from '@/helpers/app/balance';
import { orgBalanceRemainingSelector } from '@/redux/app/selectors/organization';

export const BalanceMenuHeader = () => {
  const size = 18;
  const { autoFill } = useContext(OrgInfoContext);
  const balanceRemaining = useSelector(orgBalanceRemainingSelector);

  return (
    <FlexColumn>
      <FlexColumnAlignCenter style={{ padding: '15px', borderBottom: '1px solid rgba(224, 198, 183, 0.5)' }}>
        <Text fontSize="16px" fontWeight={600}>Account Balance</Text>
        <Text fontSize="30px" fontWeight={800} color={colors.balanceGreen}>{balanceToUsd(balanceRemaining)}</Text>
      </FlexColumnAlignCenter>
      {autoFill && (
        <MenuNotif
          icon={<ArrowUpCircle size={size} style={{ rotate: '45deg' }} />}
          title="Auto Top-up: Active"
          subtitle="Account balance will auto top-up when balance is below $5."
        />
      )}
    </FlexColumn>
  )
}