import { createThunklessAction } from "thunkless";
import { avo } from '@/api/app/avo-client';
import { UserTypes } from '../../../types/user';

export interface ToggleAudioProps { userId: string }

export const setUser = ({ user }) => ({
  type: UserTypes.SET_USER,
  payload: { user },
});

export const toggleAudio = ({ userId }: ToggleAudioProps) => createThunklessAction({
  type: [
    UserTypes.TOGGLE_AUDIO_REQUEST,
    UserTypes.TOGGLE_AUDIO_SUCCESS,
    UserTypes.TOGGLE_AUDIO_FAILURE,
  ] as const,
  promise: () => avo.ajax({
    method: 'PATCH',
    route: `/users/${userId}/toggle_audio`,
  }),
});