import { useEffect, useContext } from  'react';
import { useDispatch } from 'react-redux';
import { UserInfoContext } from '@/components/subcomponents/user/user-info';
import { fetchAppNotifications } from '@/redux/app/actions/notification';

export const useFetchAppNotifications = (unix: string) => {
  const dispatch = useDispatch();
  const { userId } = useContext(UserInfoContext);

  useEffect(() => {
    if (userId) dispatch(fetchAppNotifications({ userId, lastKey: unix, limit: 10 }));
  }, [dispatch, userId])
}