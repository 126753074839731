import { useEffect, useContext } from 'react';
import { FlexJustifyBetween, FlexAlignCenter, FlexColumnAlignStart } from '../shared/Flex';
import { Menu } from 'react-feather'
import { colors } from '../ui-elements/Main/MainTheme';
import image from '../../../../assets/images/avo-icon.svg';
import { useViewport } from '@/lib/layout/dimensions';
import { BalanceDropDown } from './BalanceDropDown';
import { ToggleSound } from './ToggleSound';
import { ToggleNotifications } from './ToggleNotifications';
import { ProfileAndSettings } from './ProfileAndSettings';
import { AccountLayout } from './AccountLayout';
import { AccountInfoContext } from '../account/account-info';
import styled from 'styled-components';

import { useNavigationActions } from '@/lib/navigation';
import { formatPhone } from '@/helpers/app/phone';
import { useSetupV3OrganizationsChannel, useSetupV3UserNotificationsChannel } from '@/hooks/websockets/setup';
import { useAddLeftNavListener } from '@/hooks/misc/useAddLeftNavListener';
import { updateBalanceRemaining } from '@/redux/app/actions/organization';
import { useInit } from '@/lib/react-hooks';
import { useDispatch } from 'react-redux';
import { OrgInfoContext } from '../organization/org-info';
import { UserInfoContext } from '../user/user-info';
import { setUser } from '@/redux/app/actions/user';
import { accountPath, accountDashboardPath, accountNumbersPath } from '@/helpers/app/routes';
import { AppNavigatorPathContext } from '@/lib/navigation';

export const Root = () => {
  const user = useContext(UserInfoContext);
  const { name, phoneNumber, subdomain, isMessagingServiceSid } = useContext(AccountInfoContext);
  const path = useContext(AppNavigatorPathContext);
  const { balanceRemaining } = useContext(OrgInfoContext);
  const isMediumView = useViewport((width) => width <= 975, 500);
  const isMobile = useViewport((width) => width < 600, 500);
  const dispatch = useDispatch();
  const { navigate } = useNavigationActions();

  useSetupV3UserNotificationsChannel()
  useSetupV3OrganizationsChannel();

  const { nav, open, close } = useAddLeftNavListener();

  useEffect(() => {
    nav.classList.remove(open);
  }, [path])

  useInit(() => {
    dispatch(updateBalanceRemaining({ balanceRemaining }));
    dispatch(setUser({ user }));
  })

  const evalTitle = () => {
    if (phoneNumber) return formatPhone(phoneNumber);
    if (isMessagingServiceSid) return null;
    return 'Choose a Phone Number';
  }

  return (
    <Container>
      <FlexAlignCenter>
        {isMediumView && (
          <Menu
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={(e) => {
              nav.classList.remove(close);
              nav.classList.add(open);
            }}
          />
        )}
        <img
          src={image}
          style={{ cursor: 'pointer', width: isMediumView ? '28px' : 'auto', margin: '0 10px' }}
          onClick={() => navigate({ path: accountPath(subdomain), params: {} })}
        />
        <FlexColumnAlignStart>
          <AccountLayout
            title={name}
            toolTipTitle="Your Organization"
            toolTipSubtitle="Tip: Click to view Account Dashboard"
            callback={() => navigate({ path: accountDashboardPath(subdomain), params: {} })}
          />
          <AccountLayout
            title={evalTitle()}
            color={colors.balanceGreen}
            toolTipTitle="Your Account Number"
            toolTipSubtitle="Tip: Click to view your Phone Number Settings"
            callback={() => navigate({ path: accountNumbersPath(subdomain), params: {} })}
          />
        </FlexColumnAlignStart>
      </FlexAlignCenter>
      <FlexAlignCenter>
        {!isMobile && <BalanceDropDown />}
        <FlexAlignCenter>
          <ToggleSound />
          <ToggleNotifications />
          <ProfileAndSettings />
        </FlexAlignCenter>
      </FlexAlignCenter>
    </Container>
  )
}

const Container = styled(FlexJustifyBetween)`
  position: relative;
  background: white;
  height: 60px;
  border-bottom: 1px solid rgba(224, 198, 183, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 1000;
`;
