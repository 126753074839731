import { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  notificationAnnouncementsDataSelector,
  notificationAnnouncementsOrderSelector,
  notificationAnnouncementsLoadingSelector,
} from "@/redux/app/selectors/notification";
import { Notification } from "./Notification";
import { productReleaseNotifs } from "@/helpers/app/notification";
import { ActionStatus } from "thunkless";
import { PaneMeasurementsCanvas } from "@/lib/navigation/pane-measurements-canvas";
import { UILoader } from "../ui-elements/UILoader";
import { DynamicInfiniteList } from "../shared/DynamicInfiniteList";

export const Announcements = () => {
  const announcementsData = useSelector(notificationAnnouncementsDataSelector);
  const announcementsOrder = useSelector(notificationAnnouncementsOrderSelector);
  const announcementsLoading = useSelector(notificationAnnouncementsLoadingSelector);

  const [productReleaseArr] = productReleaseNotifs({
    notifications: announcementsData,
    order: announcementsOrder,
  })
  const renderAnnouncementItem = useCallback((index) => (
    <Notification
      key={`app-notification-${productReleaseArr[index].id}`}
      notification={productReleaseArr[index]}
    />
  ), [productReleaseArr])

  const containerRef = useRef(null);

  return (
    announcementsOrder.length > 0 ? (
      <div className="measurements-container" ref={containerRef}>
        <PaneMeasurementsCanvas className="measurements-canvas" $visible={false} style={{ width: '100%', height: 'auto'}} />
        <DynamicInfiniteList
          key="app-notifications-release-list"
          items={announcementsOrder}
          renderItem={renderAnnouncementItem}
          hasNextPage={false}
          isNextPageLoading={announcementsLoading === ActionStatus.BUSY}
          loadNextPage={() => {}}
          loader={<UILoader size="sm" center />}
          loaderHeight={50}
        />
      </div>
    ) : (
      <Empty>No Announcements</Empty>
    )
  )
}

const Empty = styled.div`
  padding: 20px;
  font-size: 14px;
`;
