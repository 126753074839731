import _ from 'lodash';

import type { ReduxState } from '@/redux/app/state';

export const notificationDataSelector = (state: ReduxState) =>
  state.notification.data

export const notificationOrderSelector = (state: ReduxState) =>
  state.notification.order

export const notificationLastKeySelector = (state: ReduxState) =>
  state.notification.lastKey

export const notificationLoadingSelector = (state: ReduxState) =>
  state.notification.loading

export const notificationUnreadSelector = (state: ReduxState) =>
  state.notification.unread

export const notificationUnreadLoadingSelector = (state: ReduxState) =>
  state.notification.unreadLoading

export const notificationAnnouncementsDataSelector = (state: ReduxState) =>
  state.notification.announcementsData

export const notificationAnnouncementsOrderSelector = (state: ReduxState) =>
  state.notification.announcementsOrder

export const notificationAnnouncementsUnreadSelector = (state: ReduxState) =>
  state.notification.announcementsUnread

export const notificationAnnouncementsLoadingSelector = (state: ReduxState) =>
  state.notification.announcementsLoading