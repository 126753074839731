import { useContext } from 'react';
import { FlexAlignStart, FlexColumn } from '../shared/Flex';
import { Text } from '../ui-elements/Main/Text';
import { SpanLink } from '../ui-elements/Main/SpanLink';
import styled from 'styled-components';
import { colors } from '../ui-elements/Main/MainTheme';
import { UserInfoContext } from '../user/user-info';
import { Avatar } from './Avatar';
import { useNavigationActions } from '@/lib/navigation';

export const MenuProfile = () => {
  const { name, email } = useContext(UserInfoContext);
  const { navigate } = useNavigationActions();
  return (
    <Container>
      <Avatar />
      <FlexColumn>
        <Text fontSize="14px" fontWeight={600}>{name}</Text>
        <Text fontSize="12px" margin="0 0 2px">{email}</Text>
        <SpanLink
          style={{ fontSize: '12px' }}
          onClick={() => navigate({ path: `${origin}/profile`, params: {} })}
        >
          Edit Profile
        </SpanLink>
      </FlexColumn>
    </Container>
  )
}

const Container = styled(FlexAlignStart)`
  width: max-content;
  padding: 20px 20px 12px 20px;
  border-bottom: 1px solid ${colors.greyer};
`;
