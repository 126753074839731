import styled from 'styled-components';
import { useViewport } from '@/lib/layout/dimensions';
import { colors } from '../ui-elements/Main/MainTheme';
import { Flex } from '../shared/Flex';

interface PaneHeadersProps {
  setPane: (string) => void;
  pane: string;
  headerData: { key: string, name: string, count?: number }[];
  displayCount?: boolean;
  isBroadcast?: boolean; // todo: refactor /billing and remove this prop
  isAnalytics?: boolean;
  buttonMargin?: string;
  background?: string;
}

export const PaneHeaders = ({
  setPane, pane, headerData, displayCount, isBroadcast, isAnalytics, buttonMargin, background = '',
}: PaneHeadersProps) => {
  const isMobile = useViewport((width) => width < 600, 500);
  const headers = headerData.map(({
    key, name, count,
  }) => {
    const isActivePane = key === pane;

    const countData = () => {
      switch (count) {
        case 0:
          return '(0)';
        case undefined:
        case null:
          return '';
        default:
          return count > 99 ? '(99+)' : `(${count})`;
      }
    };
    const title = displayCount ? `${name} ${countData()}` : name;
    return (
      <Button
        $isAnalytics={isAnalytics}
        $isBroadcast={isBroadcast}
        $isMobile={isMobile}
        $buttonMargin={buttonMargin}
        key={key}
        role="button"
        onClick={() => setPane(key)}
        onKeyPress={() => setPane(key)}
        title={title}
      >
        <Paragraph $isMobile={isMobile} className={isActivePane && 'active'} $active={isActivePane} $isAnalytics={isAnalytics}>
          {title}
        </Paragraph>
      </Button>
    );
  });

  return (
    <Container $background={background} className="panel-header">{headers}</Container>
  );
};

PaneHeaders.defaultProps = {
  displayCount: false,
};

const Container = styled(Flex)<{
  background?: string;
}>`
  background: ${({ $background }) => $background || 'none' }
`;

export const Button = styled.button<{
  isMobile: boolean,
  isBroadcast?: boolean,
  isAnalytics?: boolean,
  buttonMargin?: string,
}>`
  margin-bottom: 0;
  ${({ $isMobile, $isBroadcast }) => ($isMobile && $isBroadcast && `
    &:first-child {
      padding-left: 20px !important;
    }
  `)}
  ${({ $isMobile, $isBroadcast }) => ($isMobile && $isBroadcast && `
  &:last-child {
    padding-right: 20px !important;
  }
`)}
  margin-right: ${({ $isMobile, $buttonMargin }) => {
    if ($buttonMargin) return $buttonMargin;
    return $isMobile ? '20px !important;' : '30px !important;';
  }}
  padding: 0px;
  border: none;
  background: none;
  &::before {
    display: block;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    white-space: nowrap;
    font-family: Montserrat, sans-serif;
    font-weight: 800 !important;
    font-size: 14px;
    content: attr(title);
  }
  ${({ $isAnalytics }) => ($isAnalytics && `
    padding-left: 20px;
  `)}
`;

export const Paragraph = styled.p<{ active?: boolean, isMobile?: boolean, isAnalytics?: boolean }>`
  white-space: nowrap;
  padding: 10px 0px 12px 0px;
  cursor: pointer;
  color: ${colors.black};
  opacity: 50%;
  font-weight: 500;
  font-family: Montserrat, sans-serif!important;
  font-size: 14px;
  margin-bottom: 0;
  ${({ $active }) => ($active && `
    box-shadow: inset 0 -3px 0 0px rgba(25, 190, 81, 1);
    font-weight: 600 !important;
    color: ${colors.black};
    opacity: 100%;
  `)}

  ${({ $isMobile }) => $isMobile && `
    font-size: 14px !important;
  `};

  ${({ $isAnalytics, $active }) => (($isAnalytics && !$active) && `
    color: ${colors.secondaryGray} !important;
  `)}
`;
