export const avoAiPath = (subdomain: string) => (
  `${window.location.origin}/accounts/${subdomain}/avo_ai`
)

export const accountPath = (subdomain: string) => (
  `${window.location.origin}/accounts/${subdomain}`
)

export const accountDashboardPath = (subdomain: string) => (
  `${accountPath(subdomain)}/accounts_dashboard`
)

export const accountNumbersPath = (subdomain: string) => (
  `${accountPath(subdomain)}/numbers`
)

export const accountTicketsPath = (subdomain: string) => (
  `${accountPath(subdomain)}/tickets`
)

export const accountTicketPath = (subdomain: string, ticket_uuid: string) => (
  `${accountTicketsPath(subdomain)}/${ticket_uuid}`
)

export const accountBroadcastsPath = (subdomain: string) => (
  `${accountPath(subdomain)}/broadcast_v2`
)

export const accountBroadcastPath = (subdomain: string, broadcast_hash_id: string) => (
  `${accountBroadcastsPath(subdomain)}/${broadcast_hash_id}`
)

export const accountBillingPath = (subdomain: string) => (
  `${accountPath(subdomain)}/billing_v2`
)

export const organizationPath = (org_hash_id: string) => (
  `${window.location.origin}/organizations/${org_hash_id}`
)