// @ts-ignore
window.ReactOnRails = window.ReactOnRails || require('react-on-rails').default;

import './shared-api';

// import ReactOnRails from 'react-on-rails';
import Header from '@/components/Header';
// @ts-ignore
window.ReactOnRails.register({ Header });

// code below not needed since header is always rendered on initial load
// see: /packs/application.ts for more context
// window.ReactOnRails.reactOnRailsPageLoaded();