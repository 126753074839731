import { useContext } from "react";
import { markAllAsRead } from "@/redux/app/actions/notification";
import { colors } from "../ui-elements/Main/MainTheme";
import { UserInfoContext } from '../user/user-info';
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Text } from "../ui-elements/Main/Text";

const { darkGreen } = colors;

export const MarkAllAsRead = () => {
  const {
    userId,
  } = useContext(UserInfoContext);
  const dispatch = useDispatch();

  return (
    <MarkAsRead
      onClick={() => dispatch(markAllAsRead({ userId }))}
      fontSize="12px"
      color={darkGreen}
    >
      Mark all as Read
    </MarkAsRead>
  )
}

const MarkAsRead = styled(Text)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
