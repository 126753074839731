import { useEffect, useContext } from  'react';
import { LiveUpdates, LiveUpdatesChannel } from '@/api/app/live-updates';
import { AppSecretsContext } from '../../components/subcomponents/secrets/app-secrets';
import { AccountInfoContext } from '../../components/subcomponents/account/account-info';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { appendNotification } from '@/redux/app/actions/notification';
import { updateBalanceRemaining } from '@/redux/app/actions/organization';

export const useSetupV3UserNotificationsChannel = () => {
  const { rememberToken } = useContext(AppSecretsContext);
  const { subdomain } = useContext(AccountInfoContext);
  const dispatch = useDispatch();

  useEffect(() => {
    window.V3UsersNotificationChannel = new LiveUpdates({
      channel: LiveUpdatesChannel.V3_USERS_NOTIFICATION_CHANNEL,
      token: rememberToken || Cookies.get('remember_token'),
      subdomain,
      onConnect: () => console.log('v3 users notification channel connected'),
      onBatchReceived: (events) => {
        events.forEach((event) => {
          const { app_notification } = event;
          dispatch(appendNotification({ appNotification: app_notification }))
        })
      },
    });
  
    return () => window.V3UsersNotificationChannel.unsubscribe();
  }, [subdomain]);
}

export const useSetupV3OrganizationsChannel = () => {
  const { rememberToken } = useContext(AppSecretsContext);
  const { subdomain } = useContext(AccountInfoContext);
  const dispatch = useDispatch();

  useEffect(() => {  
    window.V3OrganizationsChannel = new LiveUpdates({
      channel: LiveUpdatesChannel.V3_ORGANIZATIONS_CHANNEL,
      token: rememberToken || Cookies.get('remember_token'),
      subdomain,
      onConnect: () => console.log('v3 organizations channel connected'),
      onBatchReceived: (events) => {
        events.forEach(({ balance }) => {
          dispatch(updateBalanceRemaining({ balanceRemaining: balance }));
        })
      },
    });
  
    return () => window.V3OrganizationsChannel.unsubscribe();
  }, [subdomain]);
}