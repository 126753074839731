import { FlexColumn } from '../shared/Flex';
import styled from 'styled-components';
import { colors } from '../ui-elements/Main/MainTheme';

export const MenuContainer = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

const Container = styled(FlexColumn)`
  z-index: 100;
  position: absolute;
  right: 10px;
  border: 1px solid ${colors.greyer};
  min-width: fit-content;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
`