import { FlexAlignCenter, FlexCenter, } from '../shared/Flex';
import { colors } from '../ui-elements/Main/MainTheme';
import { Text } from '../ui-elements/Main/Text';
import styled from 'styled-components';

export const MenuItem = ({
  icon, 
  title,
  textColor = colors.textBlack,
  callback,
}) => {
  return (
    <Container onClick={callback}>
      <Icon>
        {icon}
      </Icon>
      <Text whiteSpace="nowrap" fontSize="14px" color={textColor}>
        {title}
      </Text>
    </Container>
  )
}

const Container = styled(FlexAlignCenter)`
  cursor: pointer;
  justify-content: flex-start;
  border-radius: 12px;
  margin-bottom: 12px;
  padding: 5px 15px 5px 5px;
  &:hover {
    background-color: rgba(251, 248, 243, 1);
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const Icon = styled(FlexCenter)`
  padding: 7.5px;
  border-radius: 50px;
  background: rgba(251, 248, 243, 1);
  margin-right: 10px;
  min-width: 31px;
  min-height: 31px;
`