import { useRef, useState } from 'react';
import { Text } from '../ui-elements/Main/Text';
import { ToolTip } from './ToolTip';
import { colors } from '../ui-elements/Main/MainTheme';
import { useHover } from '@/helpers/app/hooks';
import { useViewport } from '@/lib/layout/dimensions';

export const AccountLayout = ({ color = 'black', title, toolTipTitle, toolTipSubtitle, callback }) => {
  const [show, setShow] = useState();
  const isMobile = useViewport((width) => width < 600, 500);

  const ref = useRef();
  useHover(ref, setShow);

  return (
    <div style={{ position: 'relative' }}>
      <div ref={ref} style={{ cursor: 'pointer' }}>
        <Text fontSize="14px" fontWeight="600" color={color} onClick={callback}>
          {title}
        </Text>
      </div>
      {!isMobile && show && (
        <ToolTip direction='left'>
          <Text>{toolTipTitle}</Text>
          <Text color={colors.headerYellow}>{toolTipSubtitle}</Text>
        </ToolTip>
      )}
    </div>
  )
}

