import { useContext } from 'react';
import { MenuProfile } from '../header/MenuProfile';
import { MenuDropdown } from '../header/MenuDropdown';
import { Home, CreditCard, Star, Terminal, LogOut } from 'react-feather'
import { open } from '@/redux/app/actions/navigation';
import { colors } from '../ui-elements/Main/MainTheme';
import { useDispatch } from 'react-redux';
import { AccountInfoContext } from '../account/account-info';
import { OrgInfoContext } from '../organization/org-info';
import { useNavigationActions } from '@/lib/navigation';
import { signOut } from '@/redux/app/actions/settings';
import { Avatar } from './Avatar';
import { accountBillingPath, organizationPath } from '@/helpers/app/routes';

export const ProfileAndSettings = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigationActions();
  const { subdomain } = useContext(AccountInfoContext);
  const { hashId } = useContext(OrgInfoContext);

  const size = 18;

  const menuItems = [
    { title: 'Manage Organization', icon: <Home size={size}/>, callback: () => navigate({ path: organizationPath(hashId), params: {} })},
    { title: 'Billing & Subscription', icon: <CreditCard size={size}/>, callback: () => navigate({ path: accountBillingPath(subdomain), params: {}})},
    { title: 'Whats New', icon: <Star size={size}/>, callback: () => dispatch(open({ path: `https://releases.avochato.com`, params: {} }))},
    { title: 'Developers', icon: <Terminal size={size}/>, callback: () => dispatch(open({ path: `${window.location.origin}/docs`, params: {} }))},
    { title: 'Toggle Stage', icon: <Star size={size}/>, callback: () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({version: "2", method: "switch-stage"}));
      }
    }},
    { title: 'Sign Out', icon: <LogOut size={size} color={colors.red}/>, textColor: colors.red, callback: () => dispatch(signOut())},
  ]

  return (
    <MenuDropdown
      tooltip="Profile & Settings"
      menuHeader={<MenuProfile />}
      menuItems={menuItems}
      trigger={<Avatar />}
    />
  )
}