import { useEffect } from  'react';
import { useViewport } from '@/lib/layout/dimensions';

export const useAddLeftNavListener = () => {
  const nav = document.getElementById('leftNav');
  const close = 'ui-panel-closed';
  const open = 'ui-panel-open';

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!nav.contains(e.target)) {
        nav.classList.remove(open);
        nav.classList.add(close);
      }
    }
    const event = 'mousedown';
    document.addEventListener(event, handleOutsideClick);
  
    return () => document.removeEventListener(event, handleOutsideClick);
  }, [])

  const isMediumView = useViewport((width) => width <= 975, 500);

  useEffect(() => {
    const settingsNav = document.getElementsByClassName('settings-nav-links');
    const el = settingsNav[0] as HTMLElement;
    if (el) el.style.marginTop = isMediumView ? '0px' : '60px';
  }, [isMediumView])


  return { nav, open, close };
}
