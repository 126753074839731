import styled from "styled-components";
import { FlexColumn } from "../shared/Flex";

export const ToolTip = ({ children, direction = 'right' }) => {
  return (
    <Tip $direction={direction}>
      {children}
    </Tip>
  )
}

const Tip = styled(FlexColumn)<{
  direction: string;
}>`
  font-size: 14px;
  color: white;
  background: black;
  padding: 12px 20px;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  min-width: fit-content;
  ${({ $direction }) => {
    switch ($direction) {
      case 'right':
        return 'right: 50%';
      case 'left':
        return 'left: 50%';
      default:
        return '';
    }
  }}
`;