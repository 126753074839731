import { createContext } from 'react';

export interface OrgInfoProps {
  autoFill: boolean;
  balanceRemaining: number;
  hashId: string;
}

export const OrgInfoContext = createContext<OrgInfoProps>({
  autoFill: null,
  balanceRemaining: null,
  hashId: null,
});
