import styled from 'styled-components';

export interface SpanStyleProps {
  color?: any;
  fontFamily?: any;
  fontSize?: any;
  fontWeight?: any;
  margin?: any;
  textAlign?: string;
};

export type SpanProps = React.HTMLAttributes<HTMLSpanElement> & SpanStyleProps;

export const SpanStyle = styled.span<SpanStyleProps>`
  ${(props) => (props.$color ? `color: ${props.$color} !important;` : '')}
  ${(props) => (props.$fontFamily ? `font-family: ${props.$fontFamily};` : '')}
  ${(props) => (props.$fontSize ? `font-size: ${props.$fontSize};` : '')}
  ${(props) => (props.$fontWeight ? `font-weight: ${props.$fontWeight} !important;` : '')}
  ${(props) => (props.$margin ? `margin: ${props.$margin};` : '')}
  ${(props) => (props.$textAlign ? `text-align: ${props.$textAlign};` : '')}
`;

export const Span = ({
  children, color, fontFamily, fontSize, fontWeight, margin, textAlign, ...props
}: SpanProps) => (
  <SpanStyle
    $color={color}
    $fontFamily={fontFamily}
    $fontSize={fontSize}
    $fontWeight={fontWeight}
    $margin={margin}
    $textAlign={textAlign}
    {...props}
  >
    {children}
  </SpanStyle>
);
